import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from 'ngx-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule }    from '@angular/common/http';

import { HomePageComponent } from './home-page/home-page.component';
import { FancyCloneComponent } from './fancy-clone/fancy-clone.component';
import { StartPrivateLtdComponent } from './start-private-ltd/start-private-ltd.component';
import { CopyrightComponent } from './copyright/copyright.component';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    FancyCloneComponent,
    StartPrivateLtdComponent,
    CopyrightComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    AlertModule.forRoot(),
    AppRoutingModule,
    MatToolbarModule,
    NgbModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    AngularFontAwesomeModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
