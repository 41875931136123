import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-fancy-clone',
  templateUrl: './fancy-clone.component.html',
  styleUrls: ['./fancy-clone.component.css']
})
export class FancyCloneComponent implements OnInit {
   multiLogin = true;
   uniqueFeatures = false;
   multiVendor = false;
   advancedSearch = false;
   follow = false;
   merchant = false;
   object = { }
  constructor(public httpclient:HttpClient) { }

  ngOnInit() {
    this.getPagedata();
    window.scrollTo(0, 0);
  }
  getPagedata(){
    this.httpclient.get('../assets/object.json').subscribe((response:any)=>{
      for(var i = 0; i< response.length; i++){
        console.log(window.location);
        if ('/detailPage/'+ response[i].page == window.location.pathname){
          this.object = response[i]; 
          return;      
        }else{
          this.object = response[1]; 
          return; 
        }
      }
    });
  }
  scroll(el) {
    el.scrollIntoView({behavior:"smooth"});
  }
  multiLogincont(){
   this.multiLogin = true;
   this.uniqueFeatures = false;
   this.multiVendor = false;
   this.advancedSearch = false;
   this.follow = false;
   this.merchant = false;
  }
 uniqueFeaturescont(){
   this.multiLogin = false;
   this.uniqueFeatures = true;
   this.multiVendor = false;
   this.advancedSearch = false;
   this.follow = false;
   this.merchant = false;
 }
 multiVendorcont(){
   this.multiLogin = false;
   this.uniqueFeatures = false;
   this.multiVendor = true;
   this.advancedSearch = false;
   this.follow = false;
   this.merchant = false;
 }
 advancedSearchcont(){
   this.multiLogin = false;
   this.uniqueFeatures = false;
   this.multiVendor = false;
   this.advancedSearch = true;
   this.follow = false;
   this.merchant = false;
 }
 followcont(){
   this.multiLogin = false;
   this.uniqueFeatures = false;
   this.multiVendor = false;
   this.advancedSearch = false;
   this.follow = true;
   this.merchant = false;
 }
 merchantcont(){
   this.multiLogin = false;
   this.uniqueFeatures = false;
   this.multiVendor = false;
   this.advancedSearch = false;
   this.follow = false;
   this.merchant = true;
 }
 socialMediacont(){
   this.multiLogin = false;
   this.uniqueFeatures = false;
   this.multiVendor = false;
   this.advancedSearch = false;
   this.follow = false;
   this.merchant = false;
 }
}
