import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  providers: [NgbCarouselConfig]
})
export class HomePageComponent implements OnInit {
  showNavigationArrows = true;
  showNavigationIndicators = true;

  // images = [1, 2, 3].map(() => `http://localhost:4200/assets/slider/slider1.svg`);
  
  constructor(config: NgbCarouselConfig) { 
      config.showNavigationArrows = true;
      config.showNavigationIndicators = true;
  }

  ngOnInit() { }

  scroll(el) {
    el.scrollIntoView({behavior:"smooth"});
  }
}
