import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page/home-page.component';
import { FancyCloneComponent } from './fancy-clone/fancy-clone.component';
import { CopyrightComponent } from './copyright/copyright.component';

const routes: Routes = [
{ path: '', redirectTo:'home', pathMatch:'full' },
{ path : 'home', component: HomePageComponent },
{ path : 'detailPage/1', component: FancyCloneComponent },
{ path : 'detailPage/2', component: FancyCloneComponent },
{ path : 'copy', component: CopyrightComponent },
]
@NgModule({
  imports: [
   RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
